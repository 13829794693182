import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useInfo } from "../../context";
import { ExpandableText } from "../ExpandableText";
import "./style.css";
import { formatKey } from "../../globals/formatKey";
import {
  clients,
  getImageFields,
  getPromocode,
  updateBookingEmail,
  updateClient,
  uploadImage,
  updateBooking
} from "../../services/bookingSevice";
import { formatOneDate } from "../../globals/formateDate";
import { checkStartDate } from "../../globals/checkDateType";
import { DatePicker, Space } from "antd";
import { objectToArray } from "../../globals/objectToArray";
import { getAllTrips } from "../../services/bookingSevice";


export const UpdateParticipantInfoForm = ({ newPrice, setNewPrice }) => {
  const {
    updateCurrentStep,
    handleInputChange,
    handleFieldsButtonClick,
    state,
    updateClientID,
    handleInputObjectChangeDate,
    updatePromocodeData,
    updatePromocodePercent,
    updateClientData,
    updatePackage,
    updateOptions
  } = useInfo();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTrip, setSelectedTrip] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fileIndex, setFileIndex] = useState(null);
  const [isPackageSelected, setIsPackageSelected] = useState(true);
  const [acknowledged, setAcknowledged] = useState(false);
  const [bookingDate, setBookingDate] = useState("");
  const { token, type } = useParams();

  const [trips, setTrips] = useState(null);
    useEffect(() => {
      const fetchTrips = async () => {
        try {
          const trips = await getAllTrips();
          setTrips(trips);
        } catch (error) {
          console.error("Failed to fetch travel info:", error);
        }
      };
      fetchTrips();
    }, []);


  useEffect(() => {
    if (type === "0"){
      setSelectedOption("changeTrip")
    }
    if (type === "1"){
      setSelectedOption("updateDepartureDate")
    }
    if (type === "2"){
      setSelectedOption("cancelBooking")
    }
    if (type === "3"){
      setSelectedOption("switchOptions")
    }
  }, [type]);
  console.log(type)
  useEffect(() => {
    if (selectedOption === "cancelBooking") {
      setNewPrice(0)
    }
    // else {
    //   setNewPrice(49)
    // }
  })
  const handleCurrentStepChange = (step) => {
    updateCurrentStep(step);
  };

  const fileInputRef = useRef(null);

  const totalPackagesResult = state.packages.reduce(
    (total, pkg) => total + pkg.result,
    0
  );
  const totalOptionsResult = state.options.reduce(
    (total, opt) => total + opt.result,
    0
  );
  let totalResult = totalPackagesResult + totalOptionsResult;

  if (state.promocode.percent_off !== null) {
    const discount = totalResult * (state.promocode.percent_off / 100);
    totalResult -= discount;
  }
  const packagesQuantity = state.packages
    .map((item) => {
      if (item.quantity) {
        return `${item.name} * ${item.quantity}`;
      } else {
        return item.name;
      }
    })
    .join(", ");

  const emailArray = state.participantsInfo.map((data) => data?.email?.value);

  const handlePackageChange = (id, e) => {
    let current_sum = 0
    console.log(totalPackagesResult)

    const convertPackageNameToKey = (name) => {
      return name.toLowerCase().replace(/ /g, '_');
    };

    // Calculate total price
    let totalPrice = 0;

    for (const [packageName, quantity] of Object.entries(state.booking.packages)) {
      console.log(packageName)
      const packageKey = convertPackageNameToKey(packageName);
      console.log(packageKey)
      if (state.travelInfo.package[packageKey]) {
        const packageDetails = state.travelInfo.package[packageKey];
        totalPrice += packageDetails.prices[state.booking.start_date] * quantity;
      }
    }

    console.log('Total Price:', totalPrice); // Output the total price


    const quantity = parseInt(e.target.value);
    const name = e.target.getAttribute("data-name");
    const cost = e.target.getAttribute("data-cost");
    const result = cost * quantity;
    // state.booking.packages.map((pkg) => {
    //   console.log(pkg)
    // })
    console.log(totalPrice, result,  newPrice)
    setNewPrice(result - totalPrice + newPrice)
    updatePackage(id, name, cost, quantity, result);
  };

  const handleOptionChange = (id, e) => {
    const quantity = parseInt(e.target.value);
    const name = e.target.getAttribute("data-name");
    const cost = e.target.getAttribute("data-cost");
    const result = cost * quantity;
    updateOptions(id, name, cost, quantity, result);
  };

  const generateSequence = (multiplier, pkg) => {
    if (pkg.number_of_custom_bookings) {
      const sequence = [];
      const package_name = pkg.name.split(" ").join("_").toLowerCase()

      if (state.travelInfo?.package && pkg.name &&  state.travelInfo?.package[package_name[0].toLowerCase() + package_name.slice(1)]?.remaining_spots){
        const remaining_spots = state.travelInfo?.package[package_name]?.remaining_spots
        for (let i = 1; i <= remaining_spots; i++) {

          if (i * multiplier <= remaining_spots) {
            sequence.push(i * multiplier);
          }
        }
        return sequence;
      }
      else {
        for (let i = 1; i <= pkg.number_of_custom_bookings; i++) {
          if (i * multiplier <= pkg.number_of_custom_bookings) {
            sequence.push(i * multiplier);
          }
        }
        return sequence;
      }
    }
    else {
      const quantity = state.hotelResources.filter((obj) =>
        pkg.hotel_resources.includes(obj.hotel_id)
      );

      const smallestAllocationObj = quantity.reduce((minObj, obj) => {
        return (Number(obj.allocation) < Number(minObj.allocation) ? obj : minObj);
      }, quantity[0]);

      // If you need just the smallest allocation value
      const smallestAllocation = Number(smallestAllocationObj.allocation);
      const sequence = [];
      for (let i = 1; i <= 50; i++) {
        if (i * multiplier <= smallestAllocation) {
          sequence.push(i * multiplier);
        }
      }
      return sequence;
    }
  };

  const uniqueEmails = new Set([state.buyerInfo.buyerEmail, ...emailArray]);
  const clientsData = state.participantsInfo.map((info, participantIndex) => {
    function filterFields() {
      // Destructure the object to extract the fields to be filtered
      const {
        passportImage,
        package_name,
        passportFields,
        // passportImageFile,
        ...filteredObject
      } = state.participantsInfo[participantIndex];
      return filteredObject;
    }
    return {
      first_name: info["first_name"]?.value,
      last_name: info["last_name"]?.value,
      email: info["email"]?.value,
      phone_number: "",
      passport_number: info?.passportFields?.passport_number,
      surname: info["last_name"]?.value,
      nationality: "armenian",
      date_of_birth: "2030-01-01",
      place_of_birth: info?.passportFields?.place_of_birth,
      date_of_expiration: "2030-01-01",
      agree_to_booking_terms: true,
      bed_type: "King",
      travel_insurance_info: "Insurance Company Name, Policy Number",
      interests: ["Beach", "Hiking", "Museums"],
      with_children: true,
      age_of_children: [5, 8],
      given_names: "",
      custom_info: filterFields(),
      booking_id: state.bookingID,
      //   passport_image_path: state.participantsInfo[
      //     participantIndex
      //   ].passportImageFile?.name
      //     ?.split(".")
      //     .pop(),
    };
  });

  const fetchUpdateClient = async () => {
    try {
      function extractClientData(data) {
        const {
          first_name,
          last_name,
          email,
          phone_number,
          passport_number,
          surname,
          nationality,
          date_of_birth,
          place_of_birth,
          date_of_expiration,
          travel_insurance_info,
          passport_image_path,
          interests,
          image_extension,
        } = data;

        return {
          first_name,
          last_name,
          email,
          phone_number,
          passport_number,
          surname,
          nationality,
          date_of_birth,
          place_of_birth,
          date_of_expiration,
          travel_insurance_info,
          passport_image_path,
          interests,
          image_extension,
        };
      }

      updateClient(
        state.booking.client_data[0].client_id,
        extractClientData(state.booking.client_data[0])
      );
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  const updateUserBookingDate = async (bookingId, bookingDate) => {
    try {

      console.log("booking update")
      updateBooking(bookingId, {start_date: bookingDate}, token)
      // updateBooking(
      //   state.booking.client_data[0].client_id,
      //   extractClientData(state.booking.client_data[0])
      // );
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  const updatePackageBooking = async (bookingId) => {
    try {

      const convertedObject = state.packages.reduce((acc, item) => {
        acc[item.name] = item.quantity;
        return acc;
      }, {});

      console.log(convertedObject);  // Output: { "Single rate": 3 }
      updateBooking(bookingId, {packages: convertedObject}, token)
      // updateBooking(
      //   state.booking.client_data[0].client_id,
      //   extractClientData(state.booking.client_data[0])
      // );
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  const updateAdditionalOptionsBooking = async (bookingId) => {
    try {
      console.log(state)
      const convertedObject = state.options.reduce((acc, item) => {
        acc[item.name] = item.quantity;
        return acc;
      }, {});

      console.log(convertedObject);  // Output: { "Single rate": 3 }
      updateBooking(bookingId, {additional_options: convertedObject}, token)
      // updateBooking(
      //   state.booking.client_data[0].client_id,
      //   extractClientData(state.booking.client_data[0])
      // );
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  const handleSecondContinue = async () => {
    console.log(1)
    console.log(selectedOption)
    if (selectedOption === "updateInfo") {
      await fetchUpdateClient();
    }

    if (selectedOption === "updateDepartureDate") {
      await updateUserBookingDate(state.booking.booking_id, bookingDate);
    }

    if (selectedOption === "switchPackage") {
      await updatePackageBooking(state.booking.booking_id);
    }

    if (selectedOption === "switchOptions") {
      console.log(2)
      await updateAdditionalOptionsBooking(state.booking.booking_id)
    }
    handleCurrentStepChange(state.currentStep + 1);
  };

  //   const handleFileInputChange = async (e) => {
  //     const file = e.target.files[0];
  //     // console.log(e.target.files)
  //     if (file) {
  //       // console.log(e.target.files[0])
  //       try {
  //         handleInputObjectPassportImg(file, fileIndex);
  //         // const imageData = await getImageFields(file);
  //         // Perform file upload logic here using `imageData`
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   };
  const totalQuantity = state.packages.reduce(
    (total, item) => total + item.quantity,
    0
  );

  //   const handleUploadButtonClick = (currentIndex) => {
  //     setFileIndex(currentIndex);
  //     handleFieldsButtonClick(currentIndex, true);
  //     fileInputRef.current.click();
  //   };
  const fieldsButtonClick = (currentIndex) => {
    handleFieldsButtonClick(currentIndex, false);
  };
  let totalParticipants = 0;

  function snakeCaseToTitleCase(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const datePickeOnChange = (date, dateString, index, fieldName) => {
    handleInputObjectChangeDate(fieldName, dateString, index);
  };
  let lastInputValue = "";
  const updatePromocode = async (e) => {
    updatePromocodeData(e);
    const inputValue = e.target.value;
    const promocodeData = await getPromocode(inputValue);

    if (promocodeData !== undefined) {
      updatePromocodePercent(promocodeData.percent_off);
    } else {
      updatePromocodePercent(null);
    }
  };
  function transformClientObject(obj) {
    if (obj) {
      return Object?.keys(obj)?.map((key) => ({
        field_name: key,
        value: obj[key].value,
        field_type: obj[key].field_type,
      }));
    }
  }

  return (
    <div className="participant_info_form">
      <div className="participant_info_wrapper">
      <label htmlFor="options" className="option-label">Choose an option:</label>
      <select
        id="options"
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
      >
        <option value="">Select an option</option>
        {/* <option value="updateInfo">Update Personal Information</option> */}
        <option value="updateDepartureDate">Change Departure Date</option>
        <option value="updateInfo">Change Travelling Companion</option>
        <option value="changeTrip">Change Trip</option>
        <option value="switchPackage">Switch Package</option>
        <option value="switchOptions">Change Additional Options</option>
        <option value="updateTrip">Change to a Different Trip</option>
        <option value="cancelBooking">Cancel Booking</option>
        <option value="other">Other Option</option>
      </select>

      {selectedOption === "updateInfo" && (
        state?.booking?.client_data !== undefined &&
          state?.booking?.client_data.map((client, i) => {
            return (
              <div className="participant_info" key={i}>
                <div className="title_wrapper">
                  <div className="number">{i + 1}</div>
                  <p className="participant_title">
                    Participant Information - {client.first_name}{" "}
                    {client.last_name}
                  </p>
                </div>
                <div className="group">
                  <input
                    type="text"
                    className="buyer_info_input"
                    name="first_name"
                    value={client.first_name}
                    onChange={(e) => updateClientData(e)}
                    required
                  />
                  <label htmlFor="name">First Name</label>
                </div>
                <div className="group">
                  <input
                    type="text"
                    className="buyer_info_input"
                    name="last_name"
                    value={client.last_name}
                    onChange={(e) => updateClientData(e)}
                    required
                  />
                  <label htmlFor="name">Last Name</label>
                </div>
                <div className="group">
                  <input
                    type="text"
                    className="buyer_info_input"
                    name="email"
                    value={client.email}
                    onChange={(e) => updateClientData(e)}
                    required
                  />
                  <label htmlFor="name">Email</label>
                </div>
                <div className="participant_inputs_wrapper">
                  {client.custom_info &&
                    transformClientObject(client.custom_info).map(
                      (field, i) => {
                        console.log(field);
                        return (
                          <>
                            {/* {field.field_type === "text_answer" && (
                            <div className="group">
                              <input
                                type="text"
                                className="buyer_info_input"
                                name={field.field_name}
                                value={
                                    field.value
                                }
                                // onChange={(e) =>
                                //   handleInputObjectChange(e, currentIndex)
                                // }
                                required
                              />
                              <label htmlFor="name">
                                {snakeCaseToTitleCase(field.field_name)}{" "}
                              </label>
                            </div>
                          )} */}
                            {/* {field.field_type === "checkboxes" && (
                            <div className="group">
                              <div className="participant_question_wrapper">
                                <ExpandableText
                                  text={snakeCaseToTitleCase(field.field_name)}
                                />
                                <label className="container">
                                  {field.options.map((opt, optInd) => {
                                    return (
                                      <div>
                                        <input
                                          type="checkbox"
                                            id={`policySubmit-${i}`}
                                            name={field.field_name}
                                            value={opt.value}
                                            checked={state.participantsInfo[
                                              currentIndex
                                            ][field.field_name].value.includes(
                                              opt.value
                                            )}
                                            onChange={(e) =>
                                              handleInputObjectChange(e, currentIndex)
                                            }
                                        />
                                        {opt.label}
                                        <span className="checkmark"></span>
                                      </div>
                                    );
                                  })}
                                </label>
                              </div>
                            </div>
                          )} */}
                            {/* {field.field_type === "multiple_choise" && (
                        <div className="group">
                          <div className="participant_question_wrapper">
                            <ExpandableText
                              text={snakeCaseToTitleCase(field.field_name)}
                            />
                            <label className="container">
                              {field.options.map((opt) => {
                                return (
                                  <div>
                                    <input
                                      type="radio"
                                      id={`${field.field_name}-${opt.value}-${currentIndex}`}
                                      name={field.field_name}
                                      value={opt.value}
                                      checked={
                                        state.participantsInfo[currentIndex][
                                          field.field_name
                                        ].value === opt.value
                                      }
                                      onChange={(e) =>
                                        handleInputObjectChange(e, currentIndex)
                                      }
                                    />
                                    <label
                                      htmlFor={`${field.field_name}-${opt.value}-${currentIndex}`}
                                    >
                                      {opt.label}
                                    </label>
                                  </div>
                                );
                              })}
                            </label>
                          </div>
                          {formSubmitted &&
                            !state.participantsInfo[currentIndex][
                              field.field_name
                            ].value &&
                            state.splitPayment === "no" && (
                              <p
                                className="warning_text"
                                style={{ width: "94%" }}
                              >
                                Please fill in this field
                              </p>
                            )}
                        </div>
                      )} */}
                            {/* {field.field_type === "date" && (
                        <div className="group">
                          <div className="participant_question_wrapper">
                            <ExpandableText
                              text={snakeCaseToTitleCase(field.field_name)}
                            />
                            <label className="container">
                              {field.options.map((opt) => {
                                return (
                                  <div>
                                    <Space direction="vertical">
                                      <DatePicker
                                        // value={
                                        //   state.participantsInfo[
                                        //     currentIndex
                                        //   ][field.field_name].value
                                        // }
                                        onChange={(date, dateString) =>
                                          datePickeOnChange(
                                            date,
                                            dateString,
                                            currentIndex,
                                            field.field_name
                                          )
                                        }
                                      />
                                    </Space>
                                  </div>
                                );
                              })}
                            </label>
                          </div>
                          {formSubmitted &&
                            !state.participantsInfo[currentIndex][
                              field.field_name
                            ].value &&
                            state.splitPayment === "no" && (
                              <p
                                className="warning_text"
                                style={{ width: "94%" }}
                              >
                                Please fill in this field
                              </p>
                            )}
                        </div>
                      )} */}
                          </>
                        );
                      }
                    )}
                  <div className="participant_question_wrapper">
                    {/* <ExpandableText
                      text={`Passport(s) Upload\nUpload your passport(s) to facilitate necessary arrangements e.g domestic flights\nFailure to upload absolves Heritage Africa Safaris from any liability and renders you personally responsible for any consequences resulting from inaccuracies in passport details provided.`}
                    /> */}
                    <ExpandableText text={"Passport Information"} />
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                      type="file"
                      accept="image/*,application/pdf"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileInputChange(e, i)}
                      ref={fileInputRef}
                    />
                    <button
                      className="upload_button"
                      onClick={() => handleUploadButtonClick(i)}
                    >
                      Upload
                    </button>
                      <button
                      className="upload_button"
                      onClick={() => fieldsButtonClick(i)}
                    >
                      Fill Out Fields
                    </button>
                      {state.participantsInfo[i].passportImageFile
                      .length !== 0 && (
                      <div className="image_uploaded">Image Uploaded</div>
                    )}
                    </div> */}

                    <div className="fields_wrapper">
                      {/* <div className="group small_group">
                        <input
                          type="text"
                          className="buyer_info_input"
                          name="first_name"
                          value={client.first_name}
                          onChange={(e) => updateClientData(e)}
                          required
                        />
                        <label htmlFor="first_name">First Name</label>

                      </div> */}
                      {/* <div className="group small_group">
                        <input
                          type="text"
                          className="buyer_info_input"
                          name="last_name"
                          value={client.last_name}
                          onChange={(e) => updateClientData(e)}
                          required
                        />
                        <label htmlFor="last_name">Last Name</label>

                      </div> */}
                      <div className="group small_group">
                        <input
                          type="text"
                          className="buyer_info_input"
                          name="passport_number"
                          value={client.passport_number}
                          onChange={(e) => updateClientData(e)}
                          required
                        />
                        <label htmlFor="passport_number">Passport Number</label>
                        {/* {formSubmitted &&
                          !state.participantsInfo[i].passportFields
                            .passport_number &&
                          state.splitPayment === "no" && (
                            <p
                              className="warning_text"
                              style={{ width: "94%" }}
                            >
                              Please fill in this field
                            </p>
                          )} */}
                      </div>
                      <div
                        className="group small_group"
                        style={{ marginRight: "50px" }}
                      >
                        <input
                          type="text"
                          className="buyer_info_input"
                          name="place_of_birth"
                          value={client.place_of_birth}
                          onChange={(e) => updateClientData(e)}
                          required
                        />
                        <label htmlFor="place_of_birth">City, Country</label>
                        {/* {formSubmitted &&
                          !state.participantsInfo[i].passportFields
                            .place_of_birth &&
                          state.splitPayment === "no" && (
                            <p
                              className="warning_text"
                              style={{ width: "94%" }}
                            >
                              Please fill in this field
                            </p>
                          )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
        { selectedOption === "updateDepartureDate" && (
          <div className="section_title">
            <span>Select Departure</span>
            <div
              className="package_select_wrapper"
              style={{ margin: "20px 0" }}
            >
              <select
                value={bookingDate ? bookingDate : state.booking.start_date}
                onChange={(e) => setBookingDate(e.target.value)}
                // data-cost={
                //   pkg?.prices?.value !== undefined
                //     ? pkg?.prices?.value
                //     : pkg?.price?.value
                // }
                // data-name={pkg?.name}
                style={{ width: "100px" }}
              >
                <option>Select Date</option>
                {state.travelInfo?.start_date.map((value, i) => (
                  <option value={value} key={i}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        { selectedOption === "switchPackage" && (
          <>
          <div className="section_title">
          <span>
            {state.travelInfo?.package.length === 1
              ? "Package"
              : "Select Package"}
          </span>
        </div>
        <div
          className={`${!isPackageSelected ? "warning" : "warning_default"}`}
        >
          {(state.travelInfo?.trip_occurrence === "recurring"
            ? state?.booking.start_date !== null &&
              state.travelInfo?.package_by_dates != undefined
              ? state.travelInfo?.package_by_dates[
                  state?.booking.start_date
                ] &&
                objectToArray(
                  state.travelInfo?.package_by_dates[
                    state?.booking.start_date
                  ]
                )
              : []
            : state.travelInfo?.package_by_dates[
                state?.booking.start_date
              ] &&
              objectToArray(
                state.travelInfo?.package_by_dates[
                  state?.booking.start_date
                ]
              )
          )?.map((pkg, i) => {
            return (
              <div className="package_option_wrapper" key={i}>
                <div className="package_select_wrapper">
                  <img
                    alt="User icon"
                    src="https://cdn.wetravel.com/assets/icons/icon-user-grey.svg"
                  />
                  <select
                    defaultValue={
                      state.booking.packages[pkg.name]
                    }
                    // value={
                      // state.packages?.find((pkge) => pkge.name === pkg.name)
                      //   ?.quantity || 0
                    // }
                    value={
                      state.packages?.find((pkge) => pkge.name === pkg.name)
                        ?.quantity
                    }
                    onChange={(e) => handlePackageChange(i, e)}
                    data-cost={
                      pkg?.prices?.value !== undefined
                        ? pkg?.prices?.value
                        : pkg?.price?.value
                    }
                    data-name={pkg?.name}
                  >
                    <option value={0}>{0}</option>
                    {generateSequence(Number(pkg.person_per_booking), pkg).map(
                      (value, i) => (
                        <option value={value} key={i}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="package-header">
                  <span>{formatKey(pkg?.name)}</span>
                  {/* <p className="availability">Available until Jul 7, 2024</p> */}
                </div>
                <div className="package-price">
                  <p className="price">
                    $
                    {pkg?.prices?.value !== undefined
                      ? pkg?.prices?.value
                      : pkg?.price?.value}
                  </p>
                  {pkg.deposit !== null && (
                    <p className="deposit">Deposit: {pkg.deposit}</p>
                  )}
                </div>
              </div>
            );
          })}
          </div>
          </>
        )}

        { selectedOption === "switchOptions" &&
          objectToArray(state.travelInfo?.additional_options)?.map((opt, i) => {
            return (
              <div className="package_option_wrapper" key={i}>
                <div className="package_select_wrapper">
                  <select
                    defaultValue={
                      state.booking.additional_options ? state.booking.additional_options[opt.name] : null
                    }
                    onChange={(e) => handleOptionChange(i, e)}
                    data-cost={opt.price !== undefined ? opt.price : opt.prices}
                    data-name={opt.name}
                  >
                    {Array.from(Array(25)).map((value, i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="package-header">
                  <span>{formatKey(opt?.name)}</span>
                  {/* <p className="description">Day 2 in the Morning</p> */}
                </div>
                <div className="package-price">
                  <p className="price">
                    ${opt.price !== undefined ? opt.price : opt.prices}
                  </p>
                </div>
              </div>
            );
          })
        }
        { selectedOption === "cancelBooking" && (
          <div className="cancellation_policy">
            <p>
              As per the Cancellation Policy you agreed to when joining the trip; Please be advised that ALL payments made for this trip are Non-Refundable. Due to pre-paid bookings and vendor agreements. Once confirmed, no refunds will be issued in part or whole for any reason.
            </p>
            <div className="checkbox_group">
              <input
                type="checkbox"
                id="acknowledge"
                checked={acknowledged}
                onChange={(e) => setAcknowledged(e.target.checked)}
              />
              <label htmlFor="acknowledge">
                I understand that this cancellation will have no refunds
              </label>
            </div>
          </div>
        )}

        {selectedOption === "updateTrip" && (
          <>
          <div className="participant_info" >

            <p className="participant_title">
              Please describe the trip you want to attend
            </p>
          </div>
          <div className="group">
            <textarea
              type="text"
              className="buyer_info_input"
              // name="Put the details of trip you want to change to"
              // value={client.first_name}
              onChange={(e) => updateClientData(e)}
              required
            ></textarea>
            <label htmlFor="name">Add details of preferred trip here!</label>
          </div>
          </>
        )}


        {selectedOption === "changeTrip" && (
          <>
            <label htmlFor="options" className="option-label">Choose a new trip:</label>
            <select
              id="options"
              value={selectedTrip}
              onChange={(e) => setSelectedTrip(e.target.value)}
            >
              <option value="">Select an option</option>
              {trips?.map((trip,i) => {
                return (
                  <option value={i}>{trip.name}</option>
                )}
              )}

            </select>
            {selectedTrip &&
               (
                <>
                <div className="section_title">
                <span>
                  {state.travelInfo?.package.length === 1
                    ? "Package"
                    : "Select Package"}
                </span>
              </div>
              <div
                className={`${!isPackageSelected ? "warning" : "warning_default"}`}
              >
                {(trips[selectedTrip]?.trip_occurrence === "recurring"
                  ? state?.booking.start_date !== null &&
                    trips[selectedTrip]?.package_by_dates != undefined
                    ? trips[selectedTrip]?.package_by_dates[
                        state?.booking.start_date
                      ] &&
                      objectToArray(
                        trips[selectedTrip]?.package_by_dates[
                          state?.booking.start_date
                        ]
                      )
                    : []
                  : trips[selectedTrip]?.package_by_dates[
                      state?.booking.start_date
                    ] &&
                    objectToArray(
                      trips[selectedTrip]?.package_by_dates[
                        state?.booking.start_date
                      ]
                    )
                )?.map((pkg, i) => {
                  return (
                    <div className="package_option_wrapper" key={i}>
                      <div className="package_select_wrapper">
                        <img
                          alt="User icon"
                          src="https://cdn.wetravel.com/assets/icons/icon-user-grey.svg"
                        />
                        <select
                          defaultValue={
                            state.booking.packages[pkg.name]
                          }
                          // value={
                            // state.packages?.find((pkge) => pkge.name === pkg.name)
                            //   ?.quantity || 0
                          // }
                          value={
                            state.packages?.find((pkge) => pkge.name === pkg.name)
                              ?.quantity
                          }
                          onChange={(e) => handlePackageChange(i, e)}
                          data-cost={
                            pkg?.prices?.value !== undefined
                              ? pkg?.prices?.value
                              : pkg?.price?.value
                          }
                          data-name={pkg?.name}
                        >
                          <option value={0}>{0}</option>
                          {generateSequence(Number(pkg.person_per_booking), pkg).map(
                            (value, i) => (
                              <option value={value} key={i}>
                                {value}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="package-header">
                        <span>{formatKey(pkg?.name)}</span>
                        {/* <p className="availability">Available until Jul 7, 2024</p> */}
                      </div>
                      <div className="package-price">
                        <p className="price">
                          $
                          {pkg?.prices?.value !== undefined
                            ? pkg?.prices?.value
                            : pkg?.price?.value}
                        </p>
                        {pkg.deposit !== null && (
                          <p className="deposit">Deposit: {pkg.deposit}</p>
                        )}
                      </div>
                    </div>
                  );
                })}
                </div>
                </>
              )
            }
            {selectedTrip &&
              <div className="section_title">
                <span>
                  {"Select Additional Options"}
                </span>
              </div>
            }
            {selectedTrip && (
              objectToArray(state.travelInfo?.additional_options).map((opt, i) => {
                return (
                  <div className="package_option_wrapper" key={i}>
                    <div className="package_select_wrapper">
                      <select
                        defaultValue={
                          state.booking.additional_options[opt.name]
                        }
                        onChange={(e) => handleOptionChange(i, e)}
                        data-cost={opt.price !== undefined ? opt.price : opt.prices}
                        data-name={opt.name}
                      >
                        {Array.from(Array(25)).map((value, i) => (
                          <option value={i} key={i}>
                            {i}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="package-header">
                      <span>{formatKey(opt?.name)}</span>
                      {/* <p className="description">Day 2 in the Morning</p> */}
                    </div>
                    <div className="package-price">
                      <p className="price">
                        ${opt.price !== undefined ? opt.price : opt.prices}
                      </p>
                    </div>
                  </div>
                );

              })
            )}
          </>
        )}
        {selectedOption === "other" && (
          <>
          <div className="participant_info" >

            <p className="participant_title">
              Please describe what you want to change about your booking
            </p>
          </div>
          <div className="group">
            <textarea
              type="text"
              className="buyer_info_input"
              // name="Put the details of trip you want to change to"
              // value={client.first_name}
              onChange={(e) => updateClientData(e)}
              required
            ></textarea>
            <label htmlFor="name">Add details here!</label>
          </div>
          </>
        )}
        <button className={ (selectedOption !== "" && selectedOption !== "cancelBooking") || acknowledged === true ? "continue_button" : "disabled_continue_button" } onClick={selectedOption !== "" || acknowledged === true ? handleSecondContinue : console.log("")}>
          Continue
        </button>
      </div>
    </div>
  );
};
