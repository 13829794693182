import { useEffect, useRef, useState } from "react";
import { useInfo } from "../../context";
import { ExpandableText } from "../ExpandableText";
import "./style.css";
import { formatKey } from "../../globals/formatKey";
import {
  clients,
  getImageFields,
  getPromocode,
  updateBookingEmail,
  uploadImage,
} from "../../services/bookingSevice";
import { formatOneDate } from "../../globals/formateDate";
import { checkStartDate } from "../../globals/checkDateType";
import { DatePicker, Space } from "antd";
import singleBed from '../../images/single-bed.png';
import doubleBed from '../../images/double-bed.png';

export const ParticipantInfoForm = () => {
  const {
    updateBuyerInfo,
    updateCurrentStep,
    handleInputChange,
    handleFieldsButtonClick,
    state,
    updateClientID,
    updateSplitStatus,
    handleInputObjectChange,
    handleInputObjectChangeDate,
    handleInputObjectPassportImg,
    updatePromocodeData,
    updatePromocodePercent,
    updateBedType,
    updateInsuranceOption
  } = useInfo();

  const [showFields, setShowFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fileIndex, setFileIndex] = useState(null);
  const [insuranceRadioState, setInsuranceRadioState] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  const handleCurrentStepChange = (step) => {
    updateCurrentStep(step);
  };

  const fileInputRef = useRef(null);

  const totalPackagesResult = state.packages.reduce(
    (total, pkg) => total + pkg.result,
    0
  );
  const totalOptionsResult = state.options.reduce(
    (total, opt) => total + opt.result,
    0
  );
  let totalResult = totalPackagesResult + totalOptionsResult;

  if (state.promocode.percent_off !== null) {
    const discount = totalResult * (state.promocode.percent_off / 100);
    totalResult -= discount;
  }
  const packagesQuantity = state.packages
    .map((item) => {
      if (item.quantity) {
        return `${item.name} * ${item.quantity}`;
      } else {
        return item.name;
      }
    })
    .join(", ");

  const emailArray = state.participantsInfo.map((data) => data?.email?.value);

  const uniqueEmails = new Set([state.buyerInfo.buyerEmail, ...emailArray]);
  const clientsData = state.participantsInfo.map((info, participantIndex) => {
    function filterFields() {
      // Destructure the object to extract the fields to be filtered
      const {
        passportImage,
        package_name,
        passportFields,
        passportImageFile,
        ...filteredObject
      } = state.participantsInfo[participantIndex];
      return filteredObject;
    }
    return {
      first_name: info["first_name"]?.value ? info["first_name"]?.value : state.buyerInfo.buyerName,
      last_name: info["last_name"]?.value ? info["last_name"]?.value : state.buyerInfo.buyerLastName,
      email: info["email"]?.value ? info["email"]?.value : state.buyerInfo.buyerEmail,
      phone_number: "",
      passport_number: info?.passportFields?.passport_number,
      surname: info["last_name"]?.value ? info["last_name"]?.value : "",
      nationality: "armenian",
      date_of_birth: "2030-01-01",
      place_of_birth: info?.passportFields?.place_of_birth,
      date_of_expiration: "2030-01-01",
      agree_to_booking_terms: true,
      bed_type: state.bed_type.toString(),
      insurance_option: state.insurance_option.toString(),
      travel_insurance_info: "Insurance Company Name, Policy Number",
      interests: ["Beach", "Hiking", "Museums"],
      with_children: true,
      age_of_children: [5, 8],
      given_names: "",
      custom_info: filterFields(),
      booking_id: state.bookingID,
      passport_image_path: state.participantsInfo[
        participantIndex
      ].passportImageFile?.name
        ?.split(".")
        .pop(),
    };
  });
  const fetchClients = async () => {
    try {
      const clientData = await clients({
        clients_data: clientsData,
        metadata: {
          trip_id: state.travelInfo.trip_id,
          hotel_name: state.hotelInfo.hotel_name,
          number_of_people: `${totalQuantity}`,
          hotel_email: state.hotelInfo.hotel_email,
          client_id: state.clientID,
          booking_id: state.bookingID,
          // full_name: `${state.buyerInfo.buyerName} ${state.buyerInfo.buyerLastName}`,
          first_name: state.buyerInfo.buyerName,
          last_name: state.buyerInfo.buyerLastName,
          email: state.buyerInfo.buyerEmail,
          confirmation_details: "",
          trip_name: state.travelInfo.name,
          package_name: packagesQuantity,
          price: `${totalResult}`,
          payment_plan: "",
          trip_date: `${checkStartDate(state.travelInfo?.start_date)},
            ${state.travelInfo?.end_date}`,
          today_date: formatOneDate(),
          cost: state.bookingCost / state.participantsInfo.length,
          hotel_id: state.hotelInfo.hotel_id,
          required_allocations: `${state.hotelInfo.required_allocations || 1}`,
        },
        participants_emails: emailArray[0] !== undefined ? emailArray : [""],
      });
      updateClientID(clientData.client_id);
      const urls = clientData.clients.map((item) => item.presigned_url);
      urls.map((url, i) => {
        if (state.participantsInfo[i].passportImageFile.length !== 0) {
          uploadImage(url, state.participantsInfo[i].passportImageFile);
        }
      });
      if (state.splitPayment === "yes") {
        const updateEmailResponse = await updateBookingEmail({
          booking_id: state.bookingID,
          user_emails: Array.from(uniqueEmails),
        });
      }
      return clientData;
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };
  const handleSecondContinue = async () => {
    console.log('aaa')
    state.participantsInfo.map((participant, index) => {
      fieldsButtonClick(index, false);
    });

    const emptyFields = state.participantsInfo
      .filter((participant, index) => {
        if (index === 0 || state.splitPayment === "no") {
          return Object.keys(participant)
            .filter(
              (key) => key !== "passportImage" && key !== "passportImageFile"
            )
            .some((key) => {
              if (typeof participant[key] === "object") {
                return Object.values(participant[key]).some((value) => !value);
              }
              return !participant[key];
            });
        } else {
          return !participant.email;
        }
      })
      .map((participant) =>
        Object.keys(participant)
          .filter(
            (key) => key !== "passportImage" && key !== "passportImageFile"
          )
          .map((key) => key)
      );

    // if (emptyFields.length > 0) {
    //   const fields = emptyFields.map((field) => field.join(", ")).join("; ");
    //   console.log(
    //     `Please fill in all inputs for the following fields: ${fields}`
    //   );
    //   // Set formSubmitted to true
    //   setFormSubmitted(true);
    // } else {
      await fetchClients();
      handleCurrentStepChange(state.currentStep + 1);
    // }
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    // console.log(e.target.files)
    if (file) {
      // console.log(e.target.files[0])
      try {
        handleInputObjectPassportImg(file, fileIndex);
        // const imageData = await getImageFields(file);
        // Perform file upload logic here using `imageData`
      } catch (error) {
        console.error(error);
      }
    }
  };
  const totalQuantity = state.packages.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleUploadButtonClick = (currentIndex) => {
    setFileIndex(currentIndex);
    handleFieldsButtonClick(currentIndex, true);
    fileInputRef.current.click();
  };
  const fieldsButtonClick = (currentIndex) => {
    handleFieldsButtonClick(currentIndex, false);
  };
  let totalParticipants = 0;

  function snakeCaseToTitleCase(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const datePickeOnChange = (date, dateString, index, fieldName) => {
    handleInputObjectChangeDate(fieldName, dateString, index);
  };
  let lastInputValue = "";
  const updatePromocode = async (e) => {
    updatePromocodeData(e);
    const inputValue = e.target.value;
    const promocodeData = await getPromocode(inputValue);

    console.log(promocodeData);

    if (promocodeData !== undefined) {
      updatePromocodePercent(promocodeData.percent_off);
    } else {
      updatePromocodePercent(null);
    }
    // Check if the input value has changed
    // if (inputValue !== lastInputValue) {
    //   // Update the last input value
    //   lastInputValue = inputValue;

    //   // Set a new timeout to call getPromocode after 3 seconds
    //   let timeoutId = setTimeout(() => {
    //     getPromocode(inputValue);
    //   }, 3000);
    //   // Clear the previous timeout, if any
    //   if (timeoutId) {
    //     clearTimeout(timeoutId);
    //   }
    // }
  };

  const handleRadioButtonChange = (e, currentIndex) => {
   const { value } = e.target;
   setInsuranceRadioState(value)
   updateInsuranceOption(value)
 };

 const areRequiredFieldsFilled = () => {
     // Loop through each participant's info
     return state.participantsInfo.every((participant) => {
         // Loop through each custom field defined in travelInfo
         return state.travelInfo.custom_client_fields.every((field) => {
             // Check if the field is required
             if (field.required) {
                 // Check participant's field value first
                 let fieldValue = participant[field.field_name]?.value;

                 // If the participant's field is empty, fallback to buyerInfo values
                 if (!fieldValue || fieldValue.trim() === '') {
                     if (field.field_name === "first_name") {
                         fieldValue = state.buyerInfo.buyerName;
                     } else if (field.field_name === "last_name") {
                         fieldValue = state.buyerInfo.buyerLastName;
                     } else if (field.field_name === "email") {
                         fieldValue = state.buyerInfo.buyerEmail;
                     }
                 }

                 // Final check if the value is still empty
                 return fieldValue !== undefined && fieldValue !== null && fieldValue.trim() !== '';
             }
             return true; // If the field is not required, no need to check
         });
     });
 };

 console.log(state.travelInfo.custom_client_fields)
 console.log(state.participantsInfo)
  return (
    <div className="participant_info_form">
      <div className="participant_info_wrapper">
        <div className="hotel_rating_wrapper" style={{ marginBottom: "0px" }}>
          <div className="section_title" style={{ padding: "0" }}>
            <span>Do you want split payment? </span>
            <div
              className="radio_inputs_wrapper"
              style={{ margin: "13px 0", width: "inherit" }}
            >
              <input
                type="radio"
                id="required_yes"
                value="yes"
                checked={state.splitPayment === "yes"}
                onChange={(e) => updateSplitStatus(e.target.value)}
                className="positive"
              />
              <label htmlFor="required_yes">Yes</label>
              <input
                type="radio"
                id="required_no"
                value="no"
                checked={state.splitPayment === "no"}
                onChange={(e) => updateSplitStatus(e.target.value)}
                className="negative"
              />
              <label htmlFor="required_no">No</label>
            </div>
          </div>
        </div>
        {state.packages.flatMap((pkg, packageIndex) =>
          Array.from(Array(pkg.quantity)).map((_, i) => {
            const currentIndex = totalParticipants;
            totalParticipants++;
            const participantInfo = state.participantsInfo[currentIndex];
            return (
              <div className="participant_info" key={currentIndex}>
                <div className="title_wrapper">
                  <div className="number">{currentIndex + 1}</div>
                  <p className="participant_title">
                    Participant Information - {formatKey(pkg.name)}
                  </p>
                </div>
                <div className="participant_inputs_wrapper">
                  {state.travelInfo.custom_client_fields?.map((field, i) => {
                    return (
                      <>
                        {field.field_type === "text_answer" && (
                          <div className="group">
                            <input
                              type="text"
                              className="buyer_info_input"
                              name={field.field_name}
                              value={
                                state.participantsInfo[currentIndex] &&
                                state.participantsInfo[currentIndex][field.field_name].value !== ""
                                  ? state.participantsInfo[currentIndex][field.field_name].value
                                  : (
                                      field.field_name === "first_name" ? state.buyerInfo.buyerName :
                                      field.field_name === "last_name" ? state.buyerInfo.buyerLastName :
                                      field.field_name === "email" ? state.buyerInfo.buyerEmail : ""
                                    )
                              }
                              onChange={(e) =>
                                handleInputObjectChange(e, currentIndex)
                              }
                              required
                            />
                            <label htmlFor="name">
                              {snakeCaseToTitleCase(field.field_name)}{" "}
                              {field.required && <span>*</span>}
                            </label>
                            {formSubmitted &&
                              !state.participantsInfo[currentIndex][
                                field.field_name
                              ].value &&
                              state.splitPayment === "no" && (
                                <p
                                  className="warning_text"
                                  style={{ width: "94%" }}
                                >
                                  Please fill in this field
                                </p>
                              )}
                          </div>
                        )}
                        {field.field_type === "checkboxes" && (
                          <div className="group">
                            <div className="participant_question_wrapper">
                              <ExpandableText
                                text={snakeCaseToTitleCase(field.field_name)}
                              />
                              <label className="container">
                                {field.options.map((opt, optInd) => {
                                  return (
                                    <div>
                                      <input
                                        type="checkbox"
                                        id={`policySubmit-${currentIndex}`}
                                        name={field.field_name}
                                        value={opt.value}
                                        checked={state.participantsInfo[
                                          currentIndex
                                        ][field.field_name].value.includes(
                                          opt.value
                                        )}
                                        onChange={(e) =>
                                          handleInputObjectChange(
                                            e,
                                            currentIndex
                                          )
                                        }
                                      />
                                      {opt.label}
                                      <span className="checkmark"></span>
                                    </div>
                                  );
                                })}
                              </label>
                            </div>
                            {formSubmitted &&
                              !state.participantsInfo[currentIndex][
                                field.field_name
                              ].value &&
                              state.splitPayment === "no" && (
                                <p
                                  className="warning_text"
                                  style={{ width: "94%" }}
                                >
                                  Please fill in this field
                                </p>
                              )}
                          </div>
                        )}
                        {field.field_type === "multiple_choise" && (
                          <div className="group">
                            <div className="participant_question_wrapper">
                              <ExpandableText
                                text={snakeCaseToTitleCase(field.field_name)}
                              />
                              <label className="container">
                                {field.options.map((opt) => {
                                  return (
                                    <div>
                                      <input
                                        type="radio"
                                        id={`${field.field_name}-${opt.value}-${currentIndex}`}
                                        name={field.field_name}
                                        value={opt.value}
                                        checked={
                                          state.participantsInfo[currentIndex][
                                            field.field_name
                                          ].value === opt.value
                                        }
                                        onChange={(e) =>
                                          handleInputObjectChange(
                                            e,
                                            currentIndex
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={`${field.field_name}-${opt.value}-${currentIndex}`}
                                      >
                                        {opt.label}
                                      </label>
                                    </div>
                                  );
                                })}
                              </label>
                            </div>
                            {formSubmitted &&
                              !state.participantsInfo[currentIndex][
                                field.field_name
                              ].value &&
                              state.splitPayment === "no" && (
                                <p
                                  className="warning_text"
                                  style={{ width: "94%" }}
                                >
                                  Please fill in this field
                                </p>
                              )}
                          </div>
                        )}
                        {field.field_type === "date" && (
                          <div className="group">
                            <div className="participant_question_wrapper">
                              <ExpandableText
                                text={snakeCaseToTitleCase(field.field_name)}
                              />
                              <label className="container">
                                {field.options.map((opt) => {
                                  return (
                                    <div>
                                      <Space direction="vertical">
                                        <DatePicker
                                          // value={
                                          //   state.participantsInfo[
                                          //     currentIndex
                                          //   ][field.field_name].value
                                          // }
                                          onChange={(date, dateString) =>
                                            datePickeOnChange(
                                              date,
                                              dateString,
                                              currentIndex,
                                              field.field_name
                                            )
                                          }
                                        />
                                      </Space>
                                    </div>
                                  );
                                })}
                              </label>
                            </div>
                            {formSubmitted &&
                              !state.participantsInfo[currentIndex][
                                field.field_name
                              ].value &&
                              state.splitPayment === "no" && (
                                <p
                                  className="warning_text"
                                  style={{ width: "94%" }}
                                >
                                  Please fill in this field
                                </p>
                              )}
                          </div>
                        )}
                      </>
                    );
                  })}

                  <div className="bed_type_container">


                      <div className="bed_type_option">
                        <input
                          type="radio"
                          id="single_bed"
                          name="single"
                          value={1}
                          checked={
                            state.bed_type ===
                            1
                          }
                          onChange={(e) => updateBedType(1)}
                          // onChange={(e) => console.log(e)}
                        />
                        <label htmlFor="single_bed">
                          <div className="bed_type_icon">
                            <img src={singleBed} alt="Single" />
                          </div>
                          One Bed
                        </label>
                      </div>


                      <div className="bed_type_option">
                        <input
                          type="radio"
                          id="single_bed"
                          name="single"
                          value={1}
                          // checked={
                          //   state.participantsInfo[currentIndex][field.field_name].value ===
                          //   opt.value
                          // }
                          // onChange={(e) => handleInputObjectChange(e, currentIndex)}
                          checked={
                            state.bed_type ===
                            2
                          }
                          onChange={(e) => updateBedType(2)}
                        />
                        <label htmlFor="single_bed">
                          <div className="bed_type_icon">
                            <img src={doubleBed} alt="Single" style={{height:"50px", width:"50px"}}  />
                          </div>
                          Two Separate Beds
                        </label>
                      </div>
                </div>
                  <div className="participant_question_wrapper">
                    <ExpandableText
                      text={`Passport(s) Upload\nUpload your passport(s) to facilitate necessary arrangements e.g domestic flights\nFailure to upload absolves Heritage Africa Safaris from any liability and renders you personally responsible for any consequences resulting from inaccuracies in passport details provided.`}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileInputChange(e, currentIndex)}
                        ref={fileInputRef}
                      />
                      <button
                        className="upload_button"
                        onClick={() => handleUploadButtonClick(currentIndex)}
                      >
                        Upload
                      </button>
                      <button
                        className="upload_button"
                        onClick={() => fieldsButtonClick(currentIndex)}
                      >
                        Fill Out Fields
                      </button>
                      {state.participantsInfo[currentIndex].passportImageFile
                        .length !== 0 && (
                        <div className="image_uploaded">Image Uploaded</div>
                      )}
                    </div>

                    {!state.participantsInfo[currentIndex].passportImage && (
                      <div className="fields_wrapper">
                        <div className="group small_group">
                          <input
                            type="text"
                            className="buyer_info_input"
                            name="passportFields.firstName"
                            value={
                              state.participantsInfo[currentIndex]
                                .passportFields.firstName
                            }
                            onChange={(e) => handleInputChange(e, currentIndex)}
                            required
                          />
                          <label htmlFor="passportFields.firstName">
                            First Name <span>*</span>
                          </label>
                          {formSubmitted &&
                            !state.participantsInfo[currentIndex].passportFields
                              .firstName &&
                            state.splitPayment === "no" && (
                              <p
                                className="warning_text"
                                style={{ width: "94%" }}
                              >
                                Please fill in this field
                              </p>
                            )}
                        </div>
                        <div className="group small_group">
                          <input
                            type="text"
                            className="buyer_info_input"
                            name="passportFields.lastName"
                            value={
                              state.participantsInfo[currentIndex]
                                .passportFields.lastName
                            }
                            onChange={(e) => handleInputChange(e, currentIndex)}
                            required
                          />
                          <label htmlFor="passportFields.lastName">
                            Last Name <span>*</span>
                          </label>
                          {formSubmitted &&
                            !state.participantsInfo[currentIndex].passportFields
                              .lastName &&
                            state.splitPayment === "no" && (
                              <p
                                className="warning_text"
                                style={{ width: "94%" }}
                              >
                                Please fill in this field
                              </p>
                            )}
                        </div>
                        <div className="group small_group">
                          <input
                            type="text"
                            className="buyer_info_input"
                            name="passportFields.passport_number"
                            value={
                              state.participantsInfo[currentIndex]
                                .passportFields.passport_number
                            }
                            onChange={(e) => handleInputChange(e, currentIndex)}
                            required
                          />
                          <label htmlFor="passportFields.passport_number">
                            Passport Number <span>*</span>
                          </label>
                          {formSubmitted &&
                            !state.participantsInfo[currentIndex].passportFields
                              .passport_number &&
                            state.splitPayment === "no" && (
                              <p
                                className="warning_text"
                                style={{ width: "94%" }}
                              >
                                Please fill in this field
                              </p>
                            )}
                        </div>
                        <div className="group small_group">
                          <input
                            type="text"
                            className="buyer_info_input"
                            name="passportFields.place_of_birth"
                            value={
                              state.participantsInfo[currentIndex]
                                .passportFields.place_of_birth
                            }
                            onChange={(e) => handleInputChange(e, currentIndex)}
                            required
                          />
                          <label htmlFor="passportFields.place_of_birth">
                            Country <span>*</span>
                          </label>
                          {formSubmitted &&
                            !state.participantsInfo[currentIndex].passportFields
                              .place_of_birth &&
                            state.splitPayment === "no" && (
                              <p
                                className="warning_text"
                                style={{ width: "94%" }}
                              >
                                Please fill in this field
                              </p>
                            )}
                        </div>
                      </div>
                    )}

                    <label htmlFor="radio">Will you be getting travel insurance?  As with all travel, risks are involved in the activities offered on this trip. We strongly recommend obtaining foreign medical/trip cancellation/travel insurance before participation to protect your investment in the event of unexpected situations.</label>
                    <div id="radio" style={{ display: "grid" }}>
                    <label>
                      <input
                        type="radio"
                        name={`option_${currentIndex}`}
                        value="1"
                        onChange={(e) => handleRadioButtonChange(e, currentIndex)}
                      />
                      Yes, I will be getting travel insurance
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`option_${currentIndex}`}
                        value="2"
                        onChange={(e) => handleRadioButtonChange(e, currentIndex)}
                      />
                      No - I understand the risks involved
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`option_${currentIndex}`}
                        value="3"
                        onChange={(e) => handleRadioButtonChange(e, currentIndex)}
                      />
                      I need recommendations
                    </label>
                    {insuranceRadioState === "3" &&
                      (
                        state.travelInfo.trip_id === "49b13da4-6159-45cd-a52f-f72c8057aeed" ?
                        <p>Contact Light & Life at 901-231-7673 for trip travel insurance.</p>
                        :
                        <p>Click on the below link to get insurance <a href="https://partner.globalrescue.com/heritageafricasafaris/" target="_blank">https://partner.globalrescue.com/heritageafricasafaris</a></p>
                      )
                    }
                  </div>

                  <div className="cancellation_policy">
                    <p>
                      Terms and Conditions; Please be advised that ALL payments made for this trip are Non-Refundable. Due to pre-paid bookings and vendor agreements. Once confirmed, no refunds will be issued in part or whole for any reason.  See the detailed Terms - <a href="https://heritageafrica.com/termsconditions" target="_blank">https://heritageafrica.com/termsconditions</a>
                    </p>
                    <div className="checkbox_group">
                      <input
                        type="checkbox"
                        id="acknowledge"
                        checked={acknowledged}
                        onChange={(e) => setAcknowledged(e.target.checked)}
                      />
                      <label htmlFor="acknowledge">
                        I have read and accept the terms and conditions
                      </label>
                    </div>
                  </div>

                  </div>
                </div>
              </div>
            );
          })
        )}
        <div
          className="section_title"
          style={{ padding: "0", marginBottom: "20px" }}
        >
          <span>Promocode </span>
          <div className="group">
            <input
              type="text"
              className="buyer_info_input"
              name="name"
              value={state.promocode.name}
              onChange={
                (e) => updatePromocode(e)
                //   {
                //   updatePromocodeData(e);
                //   getPromocode(e.target.value);
                // }
              }
              required
            />
            <label htmlFor="name"></label>
          </div>
          {state.promocode.percent_off !== null && (
            <p>
              Discount on your promocode makes {state.promocode.percent_off}%
            </p>
          )}
        </div>

        {/* <div className="hotel_rating_wrapper">
          <div className="section_title" style={{ padding: "0" }}>
            <span>Select your preferred rating hotel</span>
          </div>
          <div className="package_select_wrapper" style={{ width: "30%" }}>
            <select
            // onChange={(e) => handleOptionChange(i, e)}
            // data-cost={opt.price}
            // data-name={opt.name}
            >
              <option value={4}>{4}</option>
              <option value={5}>{5}</option>
            </select>
            stars
          </div>
        </div> */}
        <button className={acknowledged && insuranceRadioState && areRequiredFieldsFilled() ? "continue_button" : "disabled_continue_button"} disabled={!acknowledged || !insuranceRadioState || !areRequiredFieldsFilled()} onClick={handleSecondContinue}>
          Continue
        </button>
      </div>
    </div>
  );
};
