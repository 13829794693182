// import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
  BankAccountElement
} from "@stripe/react-stripe-js";
import "./style.css";
import { createSubscription } from "../../services/bookingSevice";
import { useInfo } from "../../context";
import {
  getDatesWithSameDayOfWeek,
  getDatesEvery15Days,
} from "../../globals/getDayOfWeekBeforeDate";
import { convertCurrency } from "../../globals/getUserCountry";

export default function CheckoutForm({clientSecret, isBuyNowPayLaterSelected, selectedPaymentMethod, totalResult}) {
  const { state } = useInfo();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const totalQuantity = state.packages.reduce(
    (total, item) => total + item.quantity,
    0
  );

  function subtractDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
  }

  //   const weeks = state.travelInfo?.start_date && getDatesWithSameDayOfWeek(new Date(`${state.travelInfo?.start_date}`));
  // const weeks = getDatesWithSameDayOfWeek(new Date("2024-10-10"));
  //   const month = state.travelInfo?.start_date && getDatesEvery15Days(new Date(state.travelInfo?.start_date))
  // const month = getDatesEvery15Days(new Date("2024-10-10"));
  const weeks = getDatesWithSameDayOfWeek(subtractDays(new Date(state.selectedRecurringDate), (state.packages[0]?.payment_due ? state.packages[0]?.payment_due : 90)));
  const month = getDatesEvery15Days(subtractDays(new Date(state.selectedRecurringDate), (state.packages[0]?.payment_due ? state.packages[0]?.payment_due : 90)));

  const handleFullSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

      const myPaymentElement = elements.getElement(PaymentElement);
      const paymentCard = elements.getElement(CardElement);

      const checkoutBody = {
        elements,

        confirmParams: {
          return_url: "https://booking.heritageafrica.com/booking-confirm",

        },
        payment_method: {
          // card: elements.getElement(CardElement),
          billing_details: {},
          currency: convertCurrency(),
        },
      }
      if (selectedPaymentMethod === "bank_transfer") {
        checkoutBody['payment_method']['bank_transfer'] = myPaymentElement
        // paymentCard = elements.getElement(BankAccountElement);
      }
      else {
        // paymentCard = elements.getElement(CardElement);
        checkoutBody['payment_method']['card'] = paymentCard
      }

    const { paymentMethod, error } = await stripe.confirmPayment(checkoutBody);

    if (error) {
      setMessage(error.message || "An unexpected error occurred.");
    }

    setIsLoading(false);
    window.location.href = 'https://booking.heritageafrica.com/booking-confirm';
  };

  const handleDueSubmit = async (e) => {
    e.preventDefault();

    const paymentMethodType = "card"; // e.g., 'card', 'us_bank_account'

    const depositAmount = state.packages[0]?.deposit ? state.packages[0].deposit : 99;

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const myPaymentElement = elements.getElement(PaymentElement);
    const paymentCard = elements.getElement(CardElement);

    const paymentDueBody = {
      type: selectedPaymentMethod === "bank_transfer" ? "bank_transfer" : "card",
      // 'card': paymentCard,
      billing_details: {
        email: state.buyerInfo.buyerEmail,
      },
    }
    if (selectedPaymentMethod === "bank_transfer") {
      paymentDueBody['bank_transfer'] = myPaymentElement
    }
    else {
      paymentDueBody['card'] = paymentCard
    }
    const { paymentMethod, error } = await stripe.createPaymentMethod(paymentDueBody);

    // console.log('paymentMethod', paymentMethod)
    // Send payment data to your backend
    const response = await createSubscription({
      email: state.buyerInfo.buyerEmail,
      payment_method_id: paymentMethod.id,
      // cost: state.bookingCost,
      cost: state.paymentDueOption === 'week' ?
        Number((totalResult - totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)) / weeks.length).toFixed(2)
        :
        Number((totalResult - totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)) / month.length).toFixed(2),
      // cost: state.bookingCost - depositAmount,
      period_duration: state.paymentDueOption,
      period_length: state.paymentDueOption === 'week' ? weeks.length : month.length,
      payment_method: paymentMethodType,
      deposit_amount: depositAmount,
      booking_id: state.bookingID,
    }, selectedPaymentMethod, paymentMethod.id);


      // }
    //   } catch (error) {
    //   console.error(error);
    // }
    setIsLoading(false);

    window.location.href = 'https://booking.heritageafrica.com/booking-confirm';
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return state.paymentOption === "full" ? (
    <div className="payment_form_wrapper">
      <form
        id="payment-form"
        className="payment_form"
        onSubmit={handleFullSubmit}
      >
      {selectedPaymentMethod === "bank_transfer" ?
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        :
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      }
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      </form>
    </div>
  ) : (
    <div className="payment_form_wrapper">
      <form
        id="payment-form"
        className="payment_form"
        onSubmit={handleDueSubmit}
        style={{ height: "57vh" }}
      >
      {selectedPaymentMethod === "bank_transfer" ?
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        :
        <CardElement id="payment-element" options={paymentElementOptions} />
      }
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          style={{ marginTop: "80px !important" }}
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      </form>
    </div>
  );
}
