import React, { createContext, useEffect, useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PACKAGE":
      return {
        ...state,
        packages: action.payload.packages,
      };
    case "UPDATE_OPTIONS":
      return {
        ...state,
        options: action.payload.options,
      };
    case "UPDATE_PAYMENT_OPTION":
      return {
        ...state,
        paymentOption: action.payload.paymentOption,
      };

    case "UPDATE_PAYMENT_DUE_OPTION":
      return {
        ...state,
        paymentDueOption: action.payload.paymentDueOption,
      };
    case "UPDATE_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    case "UPDATE_BUYER_INFO":
      return {
        ...state,
        buyerInfo: action.payload.buyerInfo,
      };
    case "UPDATE_PARTICIPANTS_INFO":
      return {
        ...state,
        participantsInfo: action.payload.participantsInfo,
      };
    case "HANDLE_INPUT_CHANGE":
      return {
        ...state,
        participantsInfo: action.payload.participantsInfo,
      };
    case "ADD_SINGLE_PARTICIPANT":
      return {
        ...state,
        packages: [
          {
            name: "",
          },
        ],
      };
    case "UPDATE_TRAVEL_INFO":
      return {
        ...state,
        travelInfo: action.payload.travelInfo,
      };
    case "UPDATE_BOOKING_TRAVEL_INFO":
      return {
        ...state,
        bookingTravelInfo: action.payload.bookingTravelInfo,
      };
    case "UPDATE_BOOKING_ID":
      return {
        ...state,
        bookingID: action.payload.bookingID,
      };
    case "UPDATE_CLIENT_ID":
      return {
        ...state,
        clientID: action.payload.clientID,
      };

    case "UPDATE_HOTEL_INFO":
      return {
        ...state,
        hotelInfo: action.payload.hotelInfo,
      };
    case "UPDATE_BOOKING_COST":
      return {
        ...state,
        bookingCost: action.payload.bookingCost,
      };
    case "UPDATE_SPLIT_PAYMENT_STATUS":
      return {
        ...state,
        splitPayment: action.payload.splitPayment,
      };
    case "UPDATE_PARTICIPANT_FIELDS":
      return {
        ...state,
        participantFields: action.payload.participantFields,
      };
    case "UPDATE_PROMOCODE_DATA":
      return {
        ...state,
        promocode: action.payload.promocode,
      };

    case "UPDATE_PREFERRED_TRIP":
      return {
        ...state,
        bed_type: action.payload.bed_type,
      };

    case "UPDATE_INSURANCE_OPTION":
      return {
        ...state,
        insurance_option: action.payload.insurance_option,
      };

    case "UPDATE_SALE_PERCENTAGE":
      return {
        ...state,
        promocode: {
          ...state.promocode,
          percent_off: action.payload.percent_off,
        },
      };
    case "UPDATE_BOOKING":
      return {
        ...state,
        booking: action.payload.booking,
      };
    case "UPDATE_CLIENT_DATA":
      return {
        ...state,
        booking: action.payload.updatedBooking,
      };
    case "UPDATE_TRIP_DATE":
      return {
        ...state,
        selectedRecurringDate: action.payload.selectedRecurringDate,
      };
    case "UPDATE_HOTEL_RESOURCES":
      return {
        ...state,
        hotelResources: action.payload.hotelResources,
      };
    case "UPDATE_FLIGHT_RESOURCES":
      return {
        ...state,
        flightResources: action.payload.flightResources,
      };
    default:
      return state;
  }
};

const InfoContext = createContext();

export const useInfo = () => React.useContext(InfoContext);

export const InfoProvider = ({ children }) => {
  const initialState = {
    currentStep: 0,
    paymentOption: "full",
    paymentDueOption: "week",
    travelInfo: null,
    packages: [],
    options: [],
    buyerInfo: {
      buyerName: "",
      buyerLastName: "",
      buyerEmail: "",
      confirmEmail: "",
    },
    participantsInfo: [],
    bookingTravelInfo: [],
    bookingID: null,
    hotelInfo: [],
    bookingCost: null,
    clientID: null,
    splitPayment: "no",
    participantFields: [],
    promocode: {
      name: "",
      percent_off: null,
    },
    booking: [],
    bed_type: 1,
    insurance_option: 1,
    selectedRecurringDate: null,
    hotelResources: [],
    flightResources: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const newParticipantsInfo = state.packages.flatMap((pkg, index) =>
      Array.from(Array(pkg.quantity)).map(() => ({
        ...state.participantFields,
        package_name: pkg.name,
        passportImage: true,
        passportImageFile: [],
        passportFields: {
          firstName: "",
          lastName: "",
          passport_number: "",
          place_of_birth: "",
        },
      }))
    );
    dispatch({
      type: "UPDATE_PARTICIPANTS_INFO",
      payload: { participantsInfo: newParticipantsInfo },
    });
  }, [state.packages]);

  const updatePackage = (id, name, cost, quantity, result, payment_due, deposit) => {
    if (quantity !== 0) {
      const updatedPackages = [
        ...state.packages.filter((pkg) => pkg.id !== id),
        { id, name, cost, quantity, result, payment_due, deposit },
      ];
      dispatch({
        type: "UPDATE_PACKAGE",
        payload: { packages: updatedPackages },
      });
    } else {
      const updatedPackages = state.packages.filter((pkg) => pkg.id !== id);
      dispatch({
        type: "UPDATE_PACKAGE",
        payload: { packages: updatedPackages },
      });
    }
  };

  const updateOptions = (id, name, cost, quantity, result) => {
    if (quantity !== 0) {
      const updatedOptions = [
        ...state.options.filter((opt) => opt.id !== id),
        { id, name, cost, quantity, result },
      ];
      dispatch({
        type: "UPDATE_OPTIONS",
        payload: { options: updatedOptions },
      });
    } else {
      const updatedOptions = state.packages.filter((opt) => opt.id !== id);
      dispatch({
        type: "UPDATE_OPTIONS",
        payload: { options: updatedOptions },
      });
    }
  };
  const updatePaymentOption = (paymentOption) => {
    dispatch({ type: "UPDATE_PAYMENT_OPTION", payload: { paymentOption } });
  };
  const updatePaymentDueOption = (paymentDueOption) => {
    dispatch({
      type: "UPDATE_PAYMENT_DUE_OPTION",
      payload: { paymentDueOption },
    });
  };

  const updateCurrentStep = (currentStep) => {
    dispatch({ type: "UPDATE_CURRENT_STEP", payload: { currentStep } });
  };

  const updateBuyerInfo = (fieldName, value) => {
    dispatch({
      type: "UPDATE_BUYER_INFO",
      payload: {
        buyerInfo: {
          ...state.buyerInfo,
          [fieldName]: value,
        },
      },
    });
  };

  const updateParticipantsInfo = (participantsInfo) => {
    dispatch({
      type: "UPDATE_PARTICIPANTS_INFO",
      payload: { participantsInfo },
    });
  };

  const handleInputChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    const updatedParticipantsInfo = [...state.participantsInfo];
    // updatedParticipantsInfo[index] = {
    //   ...updatedParticipantsInfo[index],
    //   [name]: newValue,
    // };

    if (name.startsWith("passportFields")) {
      const field = name.split(".")[1];
      updatedParticipantsInfo[index] = {
        ...updatedParticipantsInfo[index],
        passportFields: {
          ...updatedParticipantsInfo[index].passportFields,
          [field]: newValue,
        },
      };
    } else {
      updatedParticipantsInfo[index] = {
        ...updatedParticipantsInfo[index],
        [name]: newValue,
      };
    }

    dispatch({
      type: "UPDATE_PARTICIPANTS_INFO",
      payload: { participantsInfo: updatedParticipantsInfo },
    });
  };

  const handleFieldsButtonClick = (currentIndex) => {
    const updatedParticipantsInfo = state.participantsInfo.map(
      (participant, index) => {
        if (index === currentIndex) {
          return {
            ...participant,
            passportImage: false,
          };
        }
        return participant;
      }
    );

    dispatch({
      type: "UPDATE_PARTICIPANTS_INFO",
      payload: { participantsInfo: updatedParticipantsInfo },
    });
  };

  const handleInputObjectChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    const updatedParticipantsInfo = [...state.participantsInfo];

    if (type === "date") {
      // Handle date inputs
      updatedParticipantsInfo[index] = {
        ...updatedParticipantsInfo[index],
        [name]: { ...updatedParticipantsInfo[index][name], value },
      };
    } else if (type === "checkbox") {
      // Handle checkboxes
      const currentCheckboxValues =
        updatedParticipantsInfo[index][name].value || [];
      let newCheckboxValues;

      if (checked) {
        // Add the checked value if it's not already in the array
        newCheckboxValues = [...currentCheckboxValues, value];
      } else {
        // Remove the unchecked value
        newCheckboxValues = currentCheckboxValues.filter(
          (item) => item !== value
        );
      }

      updatedParticipantsInfo[index] = {
        ...updatedParticipantsInfo[index],
        [name]: {
          ...updatedParticipantsInfo[index][name],
          value: newCheckboxValues,
        },
      };
    } else {
      // Handle other input types
      updatedParticipantsInfo[index] = {
        ...updatedParticipantsInfo[index],
        [name]: { ...updatedParticipantsInfo[index][name], value },
      };
    }

    dispatch({
      type: "UPDATE_PARTICIPANTS_INFO",
      payload: { participantsInfo: updatedParticipantsInfo },
    });
  };

  const handleInputObjectChangeDate = (fieldName, value, index) => {
    const updatedParticipantsInfo = [...state.participantsInfo];

    // Handle other input types
    updatedParticipantsInfo[index] = {
      ...updatedParticipantsInfo[index],
      [fieldName]: { ...updatedParticipantsInfo[index][fieldName], value },
    };

    dispatch({
      type: "UPDATE_PARTICIPANTS_INFO",
      payload: { participantsInfo: updatedParticipantsInfo },
    });
  };
  const handleInputObjectPassportImg = (value, index) => {
    const updatedParticipantsInfo = [...state.participantsInfo];

    // Handle other input types
    updatedParticipantsInfo[index] = {
      ...updatedParticipantsInfo[index],
      passportImageFile: value,
    };

    dispatch({
      type: "UPDATE_PARTICIPANTS_INFO",
      payload: { participantsInfo: updatedParticipantsInfo },
    });
  };
  const updateTravelInfo = (travelInfo) => {
    dispatch({ type: "UPDATE_TRAVEL_INFO", payload: { travelInfo } });
  };
  const updateBookingTravelInfo = (bookingTravelInfo) => {
    dispatch({
      type: "UPDATE_BOOKING_TRAVEL_INFO",
      payload: { bookingTravelInfo },
    });
  };

  const updateBookingID = (bookingID) => {
    dispatch({
      type: "UPDATE_BOOKING_ID",
      payload: { bookingID },
    });
  };
  const updateClientID = (clientID) => {
    dispatch({
      type: "UPDATE_CLIENT_ID",
      payload: { clientID },
    });
  };
  const updateHotelInfo = (hotelInfo) => {
    dispatch({ type: "UPDATE_HOTEL_INFO", payload: { hotelInfo } });
  };
  const updateBookingCost = (bookingCost) => {
    dispatch({ type: "UPDATE_BOOKING_COST", payload: { bookingCost } });
  };
  const updateSplitStatus = (splitPayment) => {
    dispatch({
      type: "UPDATE_SPLIT_PAYMENT_STATUS",
      payload: { splitPayment },
    });
  };
  const addSingleParticipant = () => {
    dispatch({
      type: "ADD_SINGLE_PARTICIPANT",
    });
  };
  const updateParticipantField = (participantFields) => {
    dispatch({
      type: "UPDATE_PARTICIPANT_FIELDS",
      payload: { participantFields },
    });
  };

  const updatePromocodeData = (e) => {
    const { name, value } = e.target;

    dispatch({
      type: "UPDATE_PROMOCODE_DATA",
      payload: {
        promocode: {
          ...state.promocode,
          [name]: value,
        },
      },
    });
  };

  const updateBedType = (bed_type) => {
    console.log('bed_type', bed_type)
    dispatch({
      type: "UPDATE_PREFERRED_TRIP",
      payload: { bed_type: bed_type },
    });
  };

  const updateInsuranceOption = (insurance_option) => {
    console.log('bed_type', insurance_option)
    dispatch({
      type: "UPDATE_INSURANCE_OPTION",
      payload: { insurance_option: insurance_option },
    });
  };

  const updatePromocodePercent = (percent_off) => {
    dispatch({
      type: "UPDATE_SALE_PERCENTAGE",
      payload: { percent_off },
    });
  };
  const updateBooking = (booking) => {
    dispatch({
      type: "UPDATE_BOOKING",
      payload: { booking },
    });
  };
  const updateClientData = (e) => {
    const { name, value } = e.target;
    const updatedClientInfo = [...state.booking.client_data];

    updatedClientInfo[0] = {
      ...updatedClientInfo[0],
      [name]: value,
    };

    dispatch({
      type: "UPDATE_CLIENT_DATA",
      payload: {
        updatedBooking: {
          ...state.booking,
          client_data: [...updatedClientInfo],
        },
      },
    });
  };

  const updateTripDate = (selectedRecurringDate) => {
    dispatch({
      type: "UPDATE_TRIP_DATE",
      payload: { selectedRecurringDate },
    });
  };
  const updateHotelResources = (hotelResources) => {
    dispatch({
      type: "UPDATE_HOTEL_RESOURCES",
      payload: { hotelResources },
    });
  };
  const updateFlightResources = (flightResources) => {
    dispatch({
      type: "UPDATE_FLIGHT_RESOURCES",
      payload: { flightResources },
    });
  };
  return (
    <InfoContext.Provider
      value={{
        state,
        updatePackage,
        updatePaymentOption,
        updateCurrentStep,
        updateBuyerInfo,
        updateParticipantsInfo,
        handleInputChange,
        updateTravelInfo,
        updateOptions,
        updateBookingTravelInfo,
        updateBookingID,
        updateHotelInfo,
        handleFieldsButtonClick,
        updateBookingCost,
        updatePaymentDueOption,
        updateClientID,
        updateSplitStatus,
        addSingleParticipant,
        updateParticipantField,
        handleInputObjectChange,
        handleInputObjectChangeDate,
        handleInputObjectPassportImg,
        updatePromocodeData,
        updatePromocodePercent,
        updateBooking,
        updateClientData,
        updateTripDate,
        updateHotelResources,
        updateFlightResources,
        updateBedType,
        updateInsuranceOption
      }}
    >
      {children}
    </InfoContext.Provider>
  );
};

export default InfoContext;
