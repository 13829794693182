import { useState, useEffect, useRef } from "react";
import { useInfo } from "../../context";
import { clients } from "../../services/bookingSevice";

export const SplitPaymentParticipantInfo = () => {
  const {
    state,
    handleUploadButtonClick,
    handleInputChange,
    updateClientID,
    handleFieldsButtonClick,
    updateCurrentStep,
    addSingleParticipant,
    handleInputObjectPassportImg,
  } = useInfo();

  const fileInputRef = useRef(null);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const clientsData = {
    first_name: state.participantsInfo[0]?.name,
    last_name: state.participantsInfo[0]?.lastName,
    email: state.participantsInfo[0]?.email,
    phone_number: state.participantsInfo[0]?.number,
    passport_number: state.participantsInfo[0]?.passportFields.passport_number,
    surname: state.participantsInfo[0]?.lastName,
    nationality: "armenian",
    date_of_birth: "2030-01-01",
    place_of_birth: state.participantsInfo[0]?.passportFields.place_of_birth,
    date_of_expiration: "2030-01-01",
    agree_to_booking_terms: true,
    bed_type: "King",
    travel_insurance_info: "Insurance Company Name, Policy Number",
    interests: ["Beach", "Hiking", "Museums"],
    with_children: true,
    age_of_children: [5, 8],
    given_names: "",
    passport_image_path: state.participantsInfo[0].passportImageFile?.name
      ?.split(".")
      .pop(),
  };

  useEffect(() => {
    addSingleParticipant();
  }, []);

  const fieldsButtonClick = (currentIndex) => {
    handleFieldsButtonClick(currentIndex, false);
  };
  const handleCurrentStepChange = (step) => {
    updateCurrentStep(step);
  };
  const fetchClients = async () => {
    try {
      const clientData = await clients({
        clients_data: [clientsData],
        metadata: {},
        participants_emails: [],
      });
      updateClientID(clientData.client_id);
      return clientData;
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  const handleSecondContinue = async () => {
    // const emptyFields = state.participantsInfo
    //   .filter((participant) =>
    //     Object.entries(participant)
    //       .filter(
    //         ([key, value]) =>
    //           ![
    //             "passportImage",
    //             "policySubmit",
    //             "insurance",
    //             "activities",
    //             "flightBack",
    //             "package_name",
    //           ].includes(key)
    //       )
    //       .some(([key, value]) => !value)
    //   )
    //   .map((participant) =>
    //     Object.entries(participant)
    //       .filter(
    //         ([key, value]) =>
    //           ![
    //             "passportImage",
    //             "policySubmit",
    //             "insurance",
    //             "activities",
    //             "flightBack",
    //             "package_name",
    //           ].includes(key) && !value
    //       )
    //       .map(([key, value]) => key)
    //   );

    const emptyFields = state.participantsInfo
      .filter((participant, index) => {
        if (index === 0 || state.splitPayment === "no") {
          return Object.keys(participant)
            .filter(
              (key) => key !== "passportImage" && key !== "passportImageFile"
            )
            .some((key) => {
              if (typeof participant[key] === "object") {
                return Object.values(participant[key]).some((value) => !value);
              }
              return !participant[key];
            });
        } else {
          return !participant.email;
        }
      })
      .map((participant) =>
        Object.keys(participant)
          .filter(
            (key) => key !== "passportImage" && key !== "passportImageFile"
          )
          .map((key) => key)
      );

    if (emptyFields.length > 0) {
      const fields = emptyFields.map((field) => field.join(", ")).join("; ");
      console.log(
        `Please fill in all inputs for the following fields: ${fields}`
      );
      // Set formSubmitted to true
      setFormSubmitted(true);
    } else {
      await fetchClients();
      handleCurrentStepChange(state.currentStep + 1);
    }
  };
  const handleFileInputChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      try {
        handleInputObjectPassportImg(file, index);
        // const imageData = await getImageFields(file);
        // Perform file upload logic here using `imageData`
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="participant_info_form">
      <div className="participant_info_wrapper">
        <div className="participant_info">
          <div className="title_wrapper">
            {/* <div className="number">{0 + 1}</div> */}
            <p className="participant_title">Participant Information</p>
          </div>
          <div className="participant_inputs_wrapper">
            <div className="group small_group">
              <input
                type="text"
                className="buyer_info_input"
                name="name"
                value={state.participantsInfo[0]?.name}
                onChange={(e) => handleInputChange(e, 0)}
                required
              />
              <label htmlFor="name">
                First Name <span>*</span>
              </label>
              {formSubmitted && !state.participantsInfo[0]?.name && (
                <p className="warning_text" style={{ width: "94%" }}>
                  Please fill in this field
                </p>
              )}
            </div>

            <div className="group small_group">
              <input
                type="text"
                className="buyer_info_input"
                name="lastName"
                value={state.participantsInfo[0]?.lastName}
                onChange={(e) => handleInputChange(e, 0)}
                required
              />
              <label htmlFor="lastName">
                Last Name <span>*</span>
              </label>
              {formSubmitted && !state.participantsInfo[0]?.lastName && (
                <p className="warning_text" style={{ width: "94%" }}>
                  Please fill in this field
                </p>
              )}
            </div>
            <div className="group">
              <input
                type="email"
                className="buyer_info_input"
                style={{ width: "85%" }}
                name="email"
                value={state.participantsInfo[0]?.email}
                onChange={(e) => handleInputChange(e, 0)}
                required
              />
              <label htmlFor="email">
                Email <span>*</span>
              </label>
              {formSubmitted && !state.participantsInfo[0]?.email && (
                <p className="warning_text" style={{ width: "94%" }}>
                  Please fill in this field
                </p>
              )}
            </div>
            <div className="group">
              {formSubmitted && !state.participantsInfo[0]?.number && (
                <p className="warning_text" style={{ width: "94%" }}>
                  Please fill in this field
                </p>
              )}
            </div>
          </div>
          <div className="participant_question_wrapper">
            <input
              type="file"
              accept="image/*,application/pdf"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              ref={fileInputRef}
            />
            <button
              className="upload_button"
              onClick={() => handleUploadButtonClick(0)}
            >
              Upload
            </button>
            <button
              className="upload_button"
              onClick={() => fieldsButtonClick(0)}
            >
              Fill Out Fields
            </button>
            {!state.participantsInfo[0]?.passportImage && (
              <div className="fields_wrapper">
                <div className="group small_group">
                  <input
                    type="text"
                    className="buyer_info_input"
                    name="passportFields.firstName"
                    value={state.participantsInfo[0]?.passportFields?.firstName}
                    onChange={(e) => handleInputChange(e, 0)}
                    required
                  />
                  <label htmlFor="passportFields.firstName">
                    First Name <span>*</span>
                  </label>
                  {formSubmitted &&
                    !state.participantsInfo[0]?.passportFields?.firstName && (
                      <p className="warning_text" style={{ width: "94%" }}>
                        Please fill in this field
                      </p>
                    )}
                </div>
                <div className="group small_group">
                  <input
                    type="text"
                    className="buyer_info_input"
                    name="passportFields.lastName"
                    value={state.participantsInfo[0]?.passportFields?.lastName}
                    onChange={(e) => handleInputChange(e, 0)}
                    required
                  />
                  <label htmlFor="passportFields.lastName">
                    Last Name <span>*</span>
                  </label>
                  {formSubmitted &&
                    !state.participantsInfo[0]?.passportFields?.lastName && (
                      <p className="warning_text" style={{ width: "94%" }}>
                        Please fill in this field
                      </p>
                    )}
                </div>
                {/* <div className="group small_group">
                  <input
                    type="email"
                    className="buyer_info_input"
                    name="passportFields.email"
                    value={state.participantsInfo[0]?.passportFields.email}
                    onChange={(e) => handleInputChange(e, 0)}
                    required
                  />
                  <label htmlFor="passportFields.email">
                    Email <span>*</span>
                  </label>
                  {formSubmitted &&
                    !state.participantsInfo[0]?.passportFields.email && (
                      <p className="warning_text" style={{ width: "94%" }}>
                        Please fill in this field
                      </p>
                    )}
                </div> */}
                {/* <div className="group small_group">
                  <input
                    type="number"
                    className="buyer_info_input"
                    name="passportFields.phone_number"
                    value={
                      state.participantsInfo[0]?.passportFields.phone_number
                    }
                    onChange={(e) => handleInputChange(e, 0)}
                    required
                  />
                  <label htmlFor="passportFields.phone_number">
                    Phone Number <span>*</span>
                  </label>
                  {formSubmitted &&
                    !state.participantsInfo[0]?.passportFields.phone_number && (
                      <p className="warning_text" style={{ width: "94%" }}>
                        Please fill in this field
                      </p>
                    )}
                </div> */}
                <div className="group small_group">
                  <input
                    type="text"
                    className="buyer_info_input"
                    name="passportFields.passport_number"
                    value={
                      state.participantsInfo[0]?.passportFields.passport_number
                    }
                    onChange={(e) => handleInputChange(e, 0)}
                    required
                  />
                  <label htmlFor="passportFields.passport_number">
                    Passport Number <span>*</span>
                  </label>
                  {formSubmitted &&
                    !state.participantsInfo[0]?.passportFields
                      ?.passport_number && (
                      <p className="warning_text" style={{ width: "94%" }}>
                        Please fill in this field
                      </p>
                    )}
                </div>
                <div className="group small_group">
                  <input
                    type="text"
                    className="buyer_info_input"
                    name="passportFields.place_of_birth"
                    value={
                      state.participantsInfo[0]?.passportFields.place_of_birth
                    }
                    onChange={(e) => handleInputChange(e, 0)}
                    required
                  />
                  <label htmlFor="passportFields.place_of_birth">
                    City, Country <span>*</span>
                  </label>
                  {formSubmitted &&
                    !state.participantsInfo[0]?.passportFields
                      .place_of_birth && (
                      <p className="warning_text" style={{ width: "94%" }}>
                        Please fill in this field
                      </p>
                    )}
                </div>
              </div>
            )}
          </div>
          <button className="continue_button" onClick={handleSecondContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
