import { useInfo } from "../../context";
import "./style.css";
import { formatKey } from "../../globals/formatKey";

export const CheckoutInfo = () => {
  const { state } = useInfo();

  const totalPackagesResult = state.packages.reduce(
    (total, pkg) => total + pkg.result,
    0
  );
  const totalOptionsResult = state.options.reduce(
    (total, opt) => total + opt.result,
    0
  );
  let totalResult = totalPackagesResult + totalOptionsResult;

  const totalQuantity = state.packages.reduce(
    (total, item) => total + item.quantity,
    0
  );

  if (state.promocode.percent_off !== null) {
    const discount = totalResult * (state.promocode.percent_off / 100);
    totalResult -= discount;
  }

  return (
    <div className="checkout_part_wrapper">
      <div className="checkout_info_wrapper">
        <div className="underline">
          <span className="title">Your Booking</span>
        </div>
        {/* <div className="underline"> */}
        {state.packages.map((pkg, i) => {
          return (
            <div className="package_detailes_wrapper" key={i}>
              <div className="flex" style={{ alignItems: "flex-start" }}>
                <span className="trip_title">{formatKey(pkg.name)}</span>
                <p className="result_cost">${pkg.result}</p>
              </div>
              <p className="cost_details">
                {`${pkg?.quantity} x $ ${pkg?.cost}`}
              </p>
            </div>
          );
        })}
        {state.options.length !== 0 && (
          <div className="underline">
            {state.options.map((item, index) => {
              return (
                <div className="package_detailes_wrapper" key={index}>
                  <div className="flex" style={{ alignItems: "flex-start" }}>
                    <span className="trip_title">{formatKey(item.name)}</span>
                    <p className="result_cost">${item.result}</p>
                  </div>
                  <p className="cost_details">
                    {`${item?.quantity} x $ ${item?.cost}`}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex total_trip_price">
          <p
            className={`total ${state.paymentOption === "due" && "total_due"}`}
          >
            Trip Total
          </p>
          <p
            className={`total_price ${
              state.paymentOption === "due" && "total_price_due"
            }`}
          >
            $ {totalResult}
          </p>
        </div>
        {state.paymentOption === "due" && (
          <div className="due_payment flex">
            <p className="total ">Due at Booking</p>
            <p className="total_price ">$ {totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 69)}</p>
          </div>
        )}
        {state.splitPayment === "yes" && (
          <div className="due_payment flex">
            <p className="total ">Single Participant total</p>
            <p className="total_price ">
              $ {state.bookingCost / state.participantsInfo.length}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
